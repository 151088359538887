<template>
  <div v-if="!isDone || projects.length" class="popular-base">
    <section class="ProjectRecommended">
      <block-title>{{ SECTION_TITLE }}</block-title>
      <scroll-container>
        <ul class="projects">
          <project-item
            v-for="(project, i) in projects"
            :key="project.id"
            class="project-item"
            :project="project"
            :column-class="columnClass"
            :click-tracking-category="eventCategory('pc', 'project')"
            :click-tracking-label="eventLabel('recommend', 'item', i)"
          />
        </ul>
      </scroll-container>
    </section>
    <input id="recommend_user_id" type="hidden" :value="recommendUserId" />
  </div>
</template>

<script>
import api from '@/modules/api/php/recommended';
import clickTrackable from '@/mixins/clickTrackable';
import ProjectItem from '@/pc/components/ProjectItem';
import BlockTitle from '@/pc/components/top/Title';
import ScrollContainer from '@/pc/components/top/ScrollContainer';
import SECTION_TITLE from '@/consts/recommend';
import watchedProjects from '@/modules/watchedProjects';
import useIntersection from '@/composables/home/intersection';

export default {
  name: 'ProjectRecommended',
  components: {
    ProjectItem,
    BlockTitle,
    ScrollContainer,
  },
  mixins: [clickTrackable],
  props: {
    columnClass: {
      type: String,
      default: 'ProjectItem--4column ProjectItem--4column_with-button',
    },
    projectId: { type: String, required: true },
  },
  setup() {
    const { isDone, setupIntersection } = useIntersection();
    return { isDone, setupIntersection };
  },
  data() {
    return {
      SECTION_TITLE,
      projects: [],
      recommendUserId: null,
    };
  },
  created() {
    this.setupIntersection(() => this.fetchProjects());
  },
  methods: {
    async fetchProjects() {
      const viewedProjectIds = watchedProjects.listIds().join();

      try {
        const { data } = await api.fetchRecommended({
          viewedProjectIds,
          projectId: this.projectId,
        });
        this.projects = data?.projects || [];
        this.recommendUserId = data?.recommend_user_id;
      } catch (e) {
        this.projects = [];
      }
    },
  },
};
</script>

<style scoped>
.popular-base {
  background: #f5f5f5;
  display: block;
  height: auto;
  padding-top: 15px;
  width: 100%;
}

.ProjectRecommended {
  margin: 0 auto;
  padding-bottom: 36px;
  position: relative;
  width: 1000px;
}

.projects {
  display: flex;
  flex-wrap: nowrap;
}
</style>

<template>
  <li class="ProjectItem" :class="columnClass">
    <a
      :href="project.url"
      class="link"
      :class="{ 'link--without-detail': !projectWithDetail }"
      @click="pushGtmClickEvent(clickTrackingCategory, clickTrackingLabel)"
    >
      <article>
        <ThumbnailCard
          :alt="project.title"
          aspect-ratio="16 / 9"
          :height="imageSizes.height"
          :src="project.image_url"
          :width="imageSizes.width"
        />
        <h4 class="title">{{ project.title }}</h4>
        <div v-if="projectWithDetail" class="attribute">
          <div class="time">
            <template v-if="isDisplaySaleParagraph">
              <p class="sale-paragraph">販売中</p>
            </template>
            <template v-else>
              <m-icon name="time" class="icon" />
              <p>{{ project.time_left_label }}</p>
            </template>
          </div>
          <div v-if="project.hide_collected_money" class="money"></div>
          <div
            v-else-if="!project.has_target_money && !project.has_expiration"
            class="money"
          >
            <p>free goal</p>
          </div>
          <div v-else class="money">
            <p>{{ $filters.formatMoney(project.collected_money) }}円</p>
          </div>
        </div>
        <div v-if="projectWithDetail" class="bar">
          <div class="bar-background">
            <div
              class="bar-foreground"
              :style="{ width: projectBarWidth }"
            ></div>
          </div>
          <div v-if="project.has_target_money" class="bar-percent">
            {{ project.percent }}%
          </div>
        </div>
      </article>
    </a>
  </li>
</template>

<script>
import clickTrackable from '@/mixins/clickTrackable';
import projectDefinition from '@/mixins/projectDefinition';
import clickTrackingDefinition from '@/mixins/clickTrackingDefinition';
import MIcon from '@/components/MIcon';
import ThumbnailCard from '@/components/home/ThumbnailCard';

export default {
  name: 'ProjectItem',
  components: { MIcon, ThumbnailCard },
  mixins: [clickTrackable, projectDefinition, clickTrackingDefinition],
  props: {
    columnClass: {
      default: 'ProjectItem--3column',
      type: String,
    },
  },
  computed: {
    imageSizes() {
      return {
        width: 306,
        height: 172,
      };
    },
    isDisplaySaleParagraph() {
      return this.project.is_new_store_opening;
    },
  },
};
</script>

<style scoped>
.ProjectItem {
  background: #fff;
  border-radius: 4px;
  flex-shrink: 0;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
}

.ProjectItem--3column {
  margin-right: 20px;
  width: 306px;
}

.ProjectItem--3column:not(.ProjectItem--3column_with-button):nth-child(3n) {
  margin-right: 0;
}

.ProjectItem--3column_with-button {
  margin-right: 30px;
  width: 286px;
}

.ProjectItem--4column {
  margin-right: 24px;
  width: 232px;
}

.ProjectItem--4column:not(.ProjectItem--4column_with-button):nth-child(4n) {
  margin-right: 0;
}

.ProjectItem a:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.link {
  color: #333;
  display: block;
  height: 100%;
  padding-bottom: 90px;
  text-decoration: none;
}

.link--without-detail {
  padding-bottom: 17px;
}

.image {
  position: relative;
}

.title {
  font-size: 15px;
  line-height: 1.8;
  padding: 15px 15px 0;
}

/* makuake-ui-n の css を読み込まないためスタイルを記述する */
.thumbnail-card {
  display: flex;
}

.thumbnail-card :deep(.thumbnail-wrapper) {
  align-items: center;
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.attribute {
  bottom: 42px;
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.time {
  bottom: 0;
  display: flex;
  left: 14px;
  position: absolute;
}

.icon {
  fill: #a8a8a8;
  height: 15px;
  margin-right: 6px;
  width: 15px;
}

.money {
  bottom: 0;
  display: flex;
  position: absolute;
  right: 14px;
}

.bar {
  align-items: center;
  bottom: 16px;
  display: flex;
  height: 13px;
  justify-content: space-between;
  padding: 0 14px;
  position: absolute;
  width: 100%;
}

.bar-background {
  background: #e6e6e6;
  border-radius: 100px;
  flex-grow: 1;
  height: 4px;
  overflow: hidden;
}

.bar-foreground {
  background: #bbf117;
  border-radius: 0 4px 4px 0;
  height: 100%;
}

.bar-percent {
  color: #7b7b7b;
  font-size: 13px;
  margin-left: 15px;
}

.image-overlay {
  position: relative;
}

.sale-paragraph {
  background: #f7c600;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 10px;
  font-weight: bold;
  height: 19px;
  line-height: 19px;
  text-align: center;
  width: 46px;
}
</style>

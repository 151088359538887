export default {
  props: {
    clickTrackingCategory: {
      type: String,
      default: '',
      // required: true, // ほんとは必須にしたいけど、まずはオプションで
    },
    clickTrackingLabel: {
      type: String,
      default: '',
      // required: true, // ほんとは必須にしたいけど、まずはオプションで
    },
  },
};
